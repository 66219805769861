import React from "react";
import "./DontDelay.scss";
import StarRating from "../StarRating/StarRating";
import CallIcon from "../../images/white-call.svg";
import CallNotification from "../../images/call-notification.svg";
import ManHoldingBag from "../../images/man-holding-vegetables.png";
import VerifyTick from "../../images/blue-tick.svg";
import ReviewPerson from "../../images/reviewer-last.png";
const DontDelay = () => {
  return (
    <section className="dont-delay">
      <div className="container">
        <div className="wrapper">
          <div>
            <div className="star-rating">
              <StarRating rating={5} totalStars={5} width={16} height={16} />
              <span>Many Happy Customers</span>
            </div>
            <div className="dont-delay__left">
              <h1>Don’t Delay! Call To Check Your Eligibility Today!</h1>
              <p>
                Don't miss out on the opportunity to access valuable benefits
                like the spending allowance.
              </p>
              <p>
                Call us now, and we’ll help you choose a Medicare Advantage plan
                that’s right for you.
              </p>
            </div>
            <div className="dont-delay__cta">
              <div className="review-link">
                <a href="tel:+6408886518">
                  <figure>
                    <img src={CallIcon} alt="" />
                  </figure>
                  Call Toll Free (640) 888-6518
                </a>
              </div>
              <div className="company-info">
                <div className="call_number">
                  <span className="call-flex">
                    <figure className="call-n">
                      <img src={CallNotification} alt="" />
                    </figure>
                    <a href="tel:+6408886518" className="td-n">
                      (640) 888-6518
                    </a>
                  </span>
                  <p className="mob-hide">M-F 8A-6P CT. TTY 711.</p>
                  <p>Call a licensed insurance agent</p>
                </div>
              </div>
            </div>
          </div>

          <div className="dont-delay__image">
            <figure className="bag">
              <img src={ManHoldingBag} alt="" />
            </figure>

            <div className="relative">
              <div className="review-card">
                <StarRating rating={5} totalStars={5} width={16} height={16} />
                <p>
                  “More benefits than the other plan I had. I can use chiropract
                  and acupuncture as well. I was pleased with [Braxton] and he
                  explained everything very well!”
                </p>
                <div className="review-name-flex">
                  <span className="name">Anamaria Salas</span>
                  <figure>
                    <img src={VerifyTick} alt="" />
                  </figure>
                  <span className="verified">Verified customer</span>
                </div>

                <div className="reviewer-image">
                  <figure>
                    <img src={ReviewPerson} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DontDelay;
