import React from "react";
import "./FourPoints.scss";
import BlueCheck from "../../images/blue-tick-list.svg";

const FourPoints = () => {
  return (
    <section className="four-points">
      <div className="container">
        <div className="four-points__details">
          <div className="four-points__details-card">
            <figure>
              <img src={BlueCheck} alt="" />
            </figure>
            <p>Spending allowance for Healthy Groceries</p>
          </div>
          <div className="four-points__details-card">
            <figure>
              <img src={BlueCheck} alt="" />
            </figure>
            <p>Personalized support from licensed insurance agents</p>
          </div>
          <div className="four-points__details-card">
            <figure>
              <img src={BlueCheck} alt="" />
            </figure>
            <p>No extra costs with a $0 monthly plan premium</p>
          </div>
          <div className="four-points__details-card">
            <figure>
              <img src={BlueCheck} alt="" />
            </figure>
            <p>Enrollment over the phone - fast and easy</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FourPoints;
