import React from "react";
import "./Banner.scss";
import Lasso from "../../images/orange-lasso.svg";
import DoctorHoldingCard from "../../images/lady-holding-credit.png";
import OrangeCheckMark from "../../images/orange-tick.svg";
import DoubleQuote from "../../images/quote.svg";
import HappyCustomerTwo from "../../images/happy-customer-2.png";
import VerifyTick from "../../images/blue-tick.svg";
import YelloStar from "../../images/stars.svg";
import CartImage from "../../images/cart.svg";
import WhiteCall from "../../images/white-call.svg";
import CallNotification from "../../images/call-notification.svg";
import ReviewerOne from "../../images/reivewer-one.png";
import StarRating from "../StarRating/StarRating";
const Banner = () => {
  return (
    <section className="mini-bg">
      <section className="banner">
        <div className="container tab-container">
          <div className="banner__header">
            <h1>
              A Spending Allowance{" "}
              <span className="lasso-bg">
                <figure className="lasso">
                  <img src={Lasso} alt="" />
                </figure>
                Benefit
              </span>{" "}
              May Be <span className="break-header">Available To You!</span>
            </h1>
            <div className="banner__call-details desk-hide">
              <div className="call_number">
                <span className="call-flex">
                  <figure>
                    <img src={CallNotification} alt="" />
                  </figure>
                  <span>
                    &nbsp;
                    <a href="tel:+6408886518" className="td-n">
                      (640) 888-6518
                    </a>
                  </span>
                </span>
                <p>M-F 8A-6P CT. TTY 711.</p>
                <p>Call a licensed insurance agent</p>
              </div>
              <a href="tel:+6408886518" className="link-cta">
                <figure>
                  <img src={WhiteCall} alt="" />
                </figure>
                <p>
                  Call Toll Free <br /> (640) 888-6518
                </p>
              </a>
            </div>
            <div className="review_one">
              <div className="review-wrap">
                <StarRating rating={5} totalStars={5} width={16} height={16} />
                <div className="text">
                  <p className="text-p">Many Happy Customers</p>
                </div>
              </div>

              <div className="img">
                <figure>
                  <img src={ReviewerOne} alt="" />
                </figure>
              </div>
            </div>
          </div>

          <div className="banner__header-details">
            <div className="wrap">
              <figure className="doctor-figure">
                <img src={DoctorHoldingCard} alt="" />
              </figure>

              <div className="absolute-header-small desk-hide">
                <p>
                  Attention those with original Medicare <br /> and diabetes
                  and/or Cardiovascular disorders
                </p>
              </div>
              <div className="wellhelpMobile desk-hide">
                <img
                  className="well-help-image"
                  src={DoubleQuote}
                  alt=""
                  srcSet=""
                />
                <div className="orange-text">
                  We’ll help you pick a Medicare Advantage plan that gives you a
                  monthly spending allowance benefit
                </div>
              </div>
            </div>
            <div className="wrap-two">
              <div className="banner__header-details-flex">
                <div className="one">
                  <div className="details-wrap">
                    <figure>
                      <img
                        src={OrangeCheckMark}
                        alt=""
                        width={24}
                        height={24}
                      />
                    </figure>
                    <p>Spending allowance for Healthy Groceries</p>
                  </div>
                  <div className="details-wrap">
                    <figure>
                      <img
                        src={OrangeCheckMark}
                        alt=""
                        width={24}
                        height={24}
                      />
                    </figure>
                    <p>No extra costs with a $0 monthly plan premium</p>
                  </div>
                  <div className="details-wrap">
                    <figure>
                      <img
                        src={OrangeCheckMark}
                        alt=""
                        width={24}
                        height={24}
                      />
                    </figure>
                    <p>Personalized support from licensed insurance agents</p>
                  </div>
                  <div className="details-wrap">
                    <figure>
                      <img
                        src={OrangeCheckMark}
                        alt=""
                        width={24}
                        height={24}
                      />
                    </figure>
                    <p>Enrollment over the phone - fast and easy</p>
                  </div>

                  <div className="absolute-header">
                    <p>
                      Attention those with original Medicare and diabetes and/or
                      Cardiovascular disorders
                    </p>
                  </div>
                </div>

                <div className="two-div">
                  <img src={DoubleQuote} alt="" srcSet="" />
                  <div className="wellhelp">
                    We’ll help you pick a Medicare Advantage plan that gives you
                    a monthly spending allowance benefit
                  </div>
                </div>
              </div>
              <div className="banner__header-details-flex">
                <div className="correction">
                  <div className="details-wrap">
                    <figure>
                      <img
                        src={HappyCustomerTwo}
                        alt=""
                        width={24}
                        height={24}
                      />
                    </figure>
                    <div className="customer_details">
                      <span>
                        <div className="small-flex">
                          <figure>
                            <img src={YelloStar} alt="" srcSet="" />
                          </figure>
                          <figure>
                            <img src={YelloStar} alt="" srcSet="" />
                          </figure>
                          <figure>
                            <img src={YelloStar} alt="" srcSet="" />
                          </figure>
                          <figure>
                            <img src={YelloStar} alt="" srcSet="" />
                          </figure>
                          <figure>
                            <img src={YelloStar} alt="" srcSet="" />
                          </figure>
                        </div>
                        <div className="h-flex">
                          <span className="user_name">Christopher Brown</span>
                          <div className="small-flex-t">
                            <figure className="blue_tick">
                              <img src={VerifyTick} alt="" />
                            </figure>
                            <span className="verified">Verified customer</span>
                          </div>
                        </div>
                      </span>
                      <p>
                        "Its been a big deal. Its helped a lot, especially the
                        fact that I can get groceries on it at Walmart, which is
                        a big thing. ... I am very grateful.”
                      </p>
                    </div>
                    <div className="abs_img">
                      <figure>
                        <img src={CartImage} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="banner__call-details mob-hide">
            <div className="call_number">
              <span className="call-flex">
                <figure>
                  <img src={CallNotification} alt="" />
                </figure>
                <span>
                  &nbsp;
                  <a href="tel:+6408886518" className="td-n">
                    (640) 888-6518
                  </a>
                </span>
              </span>
              <p>M-F 8A-6P CT. TTY 711.</p>
              <p>Call a licensed insurance agent</p>
            </div>
            <a href="tel:+6408886518" className="link-cta">
              <figure>
                <img src={WhiteCall} alt="" />
              </figure>
              <p>
                Call Toll Free <br /> (640) 888-6518
              </p>
            </a>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Banner;
