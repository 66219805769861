import React from "react";
import "./Header.scss";
import Logo from "../../images/logo.svg";
import HeaderClock from "../../images/header-clock.svg";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <header className="container">
      <div className="wrapper">
        <Link to="/">
          <div className="logo">
            <figure>
              <img src={Logo} alt="" />
            </figure>

            <div className="header__details">
              <h2>
                American
                <span>Extra Help</span>
              </h2>
              <p>Non-Government Entity</p>
            </div>
          </div>
        </Link>
        <div className="business-timing">
          <figure>
            <img src={HeaderClock} alt="" />
          </figure>
          <div>
            <p>Monday - Friday 8AM - 6PM</p>
            <p>Central Time TTY:711</p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
