const Reviews = [
  {
    id: 1,
    title: "So far so good",
    text: "So far so good, I like my plan. I can go to the grocery store and buy me some food.",
    name: "Edna Moore",
    rating: 5,
  },
  {
    id: 2,
    title: "I think he did a great job",
    text: "Hi This is Cecilia Rohdy and George Gergely really helped me get my new Aetna plan and its working out really great. He was nice and considerate and he gave me several different options to choose from but I chose Aetna and I think he did a great job",
    name: "Cecilia Rohdy",
    rating: 5,
  },
  {
    id: 3,
    title: "She was very patient with me",
    text: "Elena was excellent helping me with the plan. She was Excellent!. I was pleased with the way she explained how the insurance works… I was pleased with the way she talked to me and the way she explained everything to me because I am a senior and some people do not have the time and patience but she was very very very patient with me and I thank her for that.",
    name: "Doreen Nelson",
    rating: 5,
  },
  {
    id: 4,
    title: "Very helpful",
    text: "Lesley was very helpful and answered all my questions and she was very very good and helpful and polite",
    name: "Steven Synder",
    rating: 5,
  },
  {
    id: 5,
    title: "More benefits",
    text: "More benefits than the other plan I had. I can use chiropract and acupuncture as well. I was pleased with Braxton and he explained everything very well!",
    name: "Anamaria Salas",
    rating: 5,
  },
];

export default Reviews;
